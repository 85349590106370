@use 'sass:map';

$lg: '1440px';
$ml: '1024px';
$ms: '960px';
$md: '768px';
$sm: '540px';
$smer: '360px';

$viewports: (
  'lg': '1440px',
  'ls': '1100px',
  'ml': '1024px',
  'ms': '960px',
  'md': '768px',
  'sm': '540px',
  'smer': '360px',
);

@mixin media($size) {
  @media only screen and (min-width: map.get($viewports, $size)) {
    @content;
  }
}

// These mixins are deprecated, just use media()
@mixin bp-large() {
  @media only screen and (max-width: $lg) {
    @content;
  }
}

@mixin bp-mediumLarge() {
  @media only screen and (max-width: $ml) {
    @content;
  }
}

@mixin bp-mediumScreen() {
  @media only screen and (max-width: $ms) {
    @content;
  }
}

@mixin bp-medium() {
  @media only screen and (max-width: $md) {
    @content;
  }
}

@mixin bp-small() {
  @media only screen and (max-width: $sm) {
    @content;
  }
}

@mixin bp-smaller() {
  @media only screen and (max-width: $smer) {
    @content;
  }
}
