@mixin page-container() {
  max-width: $page-width;
  margin: 0 auto;
}

@mixin container-padding() {
  padding-inline: 10px;

  @include media('smer') {
    padding-inline: 20px;
  }
}

@mixin toast {
  padding: 5px;
  color: white;
  cursor: default;
  background-color: $secondary;
  border-radius: 12px;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    position: absolute;
    z-index: $top-layer;
    width: 5px;

    @include media('sm') {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e6dcee;
    border-radius: 10px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c9c0cf;
  }
}

@mixin chat-input() {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  background-color: $background;
  border: solid $background 2px;
  border: 1px solid rgba(32 17 80 / 10%);
  border-radius: 10px;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    border: solid $primary 1px;
    outline: none;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e6dcee;
    border-radius: 10px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c9c0cf;
  }
}

@mixin home-title() {
  padding: 0 15px;
  font-family: $title-font;
  font-size: 64px;
  line-height: 70px;
  text-align: center;

  @include bp-medium() {
    font-size: 40px;
    line-height: 45px;
  }

  @include bp-small() {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin home-subtitle() {
  position: relative;
  width: 100%;
  margin-top: 30px;
  font-family: $content-font;
  font-size: 32px;
  font-weight: lighter;
  color: $primary;
  text-align: center;

  @include bp-medium() {
    font-size: 26px;
  }

  @include bp-small() {
    font-size: 21px;
    line-height: 26px;
  }
}

@mixin home-bg-img() {
  position: relative;
  top: -200px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  pointer-events: none;

  @media only screen and (width <= 816px) {
    display: none;
  }
}

@mixin link() {
  font-weight: 600;
  color: $secondary;
  text-decoration: underline;
}

@mixin skeleton() {
  @keyframes skeleton-animation {
    0% {
      right: 100%;
    }

    50% {
      right: -128px;
    }

    100% {
      right: 100%;
    }
  }
}

@mixin skeleton() {
  background-color: #f4f4f4;
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  background-size: 40px 100%;
  animation: shine 1s ease infinite;

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
}

@mixin skeleton-slow() {
  background-color: #f4f4f4;
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-repeat: no-repeat;
  background-position: left -200px top 0;
  background-size: 200px 100%;
  animation: shine 2s ease infinite;

  @keyframes shine {
    to {
      background-position: right -200px top 0;
    }
  }
}

@mixin reset-button() {
  width: fit-content;
  font-family: $content-font;
  font-size: 15px;
  font-weight: 600;
  color: black;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  transition:
    background 250ms ease-in-out,
    transform 150ms ease;
}

@mixin reset-input {
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
}
