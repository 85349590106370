@import '@styles/theme/theme';

.input {
  @include chat-input();

  min-width: 240px;

  @include bp-small() {
    min-width: unset;
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      border: 1px solid #ccc;
    }
  }
}

.label {
  margin: 10px auto -5px;
  font-weight: bold;
}

.error {
  position: relative;
  min-width: 240px;
  margin-top: 10px;
  font-size: 13px !important;
  color: $error;

  @include bp-small() {
    min-width: unset;
  }
}

.password-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  label {
    width: 100%;
  }

  button {
    position: absolute;
    padding: 0;
    margin: 20px 10px 0 0;
    background-color: transparent;
  }
}
